@import "../../assets/styles/variables";
@import "../../assets/styles/breakpoints";

.contacts {
  padding: 100px 0 50px 0;
  color: $color-secondary;
  background: $color-bg-main no-repeat -100px calc(100% + 100px) / 50%; //url("../../assets/images/screens/contacts/background.svg");

  @include media-breakpoint-down(lg) {
    background-position: -200px 250px;
  }

  @include media-breakpoint-down(md) {
    background-position: -200px 80%;
  }

  @include media-breakpoint-down(sm) {
    background-position: calc(100% + 100px) 50%;
  }

  @include media-breakpoint-down(xs) {
    background-image: none;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    flex-basis: calc(50% - 50px);
  }

  @include media-breakpoint-down(lg) {
    flex-direction: column;

    > div:first-child {
      order: 1;
    }
  }
}

.info {
  @include media-breakpoint-down(lg) {
    padding-left: 100px;
  }
  @include media-breakpoint-down(md) {
    padding: 0;
  }
}

.subTitle {
  margin-bottom: 25px;
  font-size: 1.75rem;
  font-weight: 500;
  color: $color-main;
}

.title {
  margin-bottom: 45px;
  font-size: 3rem;
  color: $color-white;
}

.text {
  margin-bottom: 19px;
  font-size: 1.25rem;
  line-height: 1.8rem;

  @include media-breakpoint-down(sm) {
    margin-bottom: 12px;
  }

  &:nth-child(3) {
    margin-bottom: 40px;
  }

  > a {
    display: block;
    width: max-content;
    color: $color-secondary;
    transition: .2s;
  }
}

.mail:hover {
  color: $color-main;
}

.followUs {
  color: $color-main;
  font-size: 1.25rem;
  font-weight: 600;
}

.rights {
  margin-top: 50px;
  text-align: center;
}

a.phoneNumber {
  display: inline-block;
  font-weight: 500;
  font-size: 1.25rem;
  color: $color-main;
}

#underline {
  width: 169px;
  padding-top: 19px;
  background: no-repeat bottom / contain;
  background-image: url("../../assets/images/screens/contacts/dashed.svg");

  @include media-breakpoint-down(sm) {
    width: 153px;
    padding-top: 15px;
  }
}

.socialNetworks {
  display: flex;
  align-items: center;
  padding: 21px 0;

  @include media-breakpoint-down(lg) {
    padding: 19px 0;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 0;
  }

  > a {
    display: flex;
    align-items: center;
    transition: .2s;

    .bg {
      height: 40px;
      padding-right: 55px;
      background: no-repeat left / contain;
      transition: .2s;
    }

    &:not(:last-child) {
      margin-right: 30px;

      @include media-breakpoint-down(sm) {
        margin: 0 0 11px;
      }
    }

    .txt {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
      color: $color-secondary;

      span {
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 1.2rem;
        color: $color-main;
      }
    }

    &:hover {
      .bg {
        filter: drop-shadow(0px 1px 16px #0169E3);
      }
    }

    @include media-breakpoint-down(md) {
      &:hover {
        .bg {
          filter: none;
        }
      }
    }
  }

  $path-to-icons: "../../assets/images/screens/contacts/social-networks/";

  > .skypeLink {
    background-image: url($path-to-icons + "skype.svg");
  }

  > .linkedinLink {
    background-image: url($path-to-icons + "linkedin.svg");
  }

  > .facebookLink {
    background-image: url($path-to-icons + "facebook.svg");
  }

  > .telegramLink .bg {
    background-image: url($path-to-icons + "telegram.svg");
  }

  > .watsAppLink .bg {
    background-image: url($path-to-icons + "whatsapp.svg");
  }
}
