.next, .prev {
  position: absolute;
  top: 50%;
  width: 30px;
  height: 18px;
  cursor: pointer;

  > path {
    fill: rgb(109, 109, 109);
    transition: .2s;
  }

  &:hover > path {
    fill: rgb(174, 174, 174);
  }
}

.next {
  right: -40px;
  transform: translateY(-50%) rotate(-90deg);
}

.prev {
  left: -40px;
  transform: translateY(-50%) rotate(90deg);
}
