@import "../../../assets/styles/breakpoints";
@import "../../../assets/styles/variables";

.burgerMenu {
  display: none;
  width: 30px;
  height: 23px;
  position: relative;
  margin-left: 40px;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;

  @include media-breakpoint-down(md) {
    display: block;
  }

  @include media-breakpoint-down(sm) {
    margin-left: 15px;
  }
}

.line {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: $color-white;
  border-radius: 15px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.line {
  &:nth-child(1) {
    top: 0;

    &.open {
      top: 14px;
      width: 0;
      left: 50%;
    }
  }

  &:nth-child(2) {
    top: 10px;

    &.open {
      transform: rotate(45deg);
    }
  }

  &:nth-child(3) {
    top: 10px;

    &.open {
      transform: rotate(-45deg);
    }
  }

  &:nth-child(4) {
    top: 20px;

    &.open {
      top: 14px;
      width: 0;
      left: 50%;
    }
  }
}

.menuWrapper {
  position: fixed;
  display: none;
  top: 105px;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: column;
  width: 100vw;
  min-height: 100%;
  padding: 15px 50px;
  height: calc(100vh - 105px);
  border-top: 1px solid #424245;
  transition: .4s;
  transform: translateX(-100%);
  background-color: $color-bg-main;
  z-index: 1;

  &.open {
    transform: translateX(0);
  }

  @include media-breakpoint-down(md) {
    display: flex;
  }

  @include media-breakpoint-down(sm) {
    padding: 15px 30px;
  }

  @include media-breakpoint-down(xs) {
    padding: 15px;
  }
}

.menuItem {
  margin-bottom: 15px;
  font-size: 20px;

  &:before {
    content: "";
    display: inline-block;
    position: absolute;
    left: -8px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 5px;
    width: 2px;
    height: 20px;
    background-color: $color-main;
    margin-right: 5px;
  }
}
