@import "../../assets/styles/variables";
@import "../../assets/styles/breakpoints";

.title {
  display: inline-block;
  position: relative;
  margin-bottom: 60px;
  font-size: 1.75rem;

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -10px;
    width: 100%;
    height: 4px;
    border-radius: 4px 4px 0 0;
    background-color: $color-main;
  }

  &.center {
    display: block;
    width: max-content;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
}
