@import "../../assets/styles/variables";
@import "../../assets/styles/breakpoints";

.main {
  padding: 75px 0;
  color: $color-white;
  background-color: $color-bg-main;
  background-image: url("../../assets/images/screens/main/background.png");

  @include media-breakpoint-down(md) {
    margin-top: 105px;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.subTitle {
  font-size: 1.75rem;
  margin-bottom: 28px;
  word-break: break-word;
}

.title {
  font-size: 3rem;
  font-weight: 500;
}

.list {
  margin-top: 40px;
  font-size: 1.25rem;

  > li {
    display: flex;
    align-items: center;
    list-style: none;
    margin-bottom: 10px;

    &:before {
      content: "";
      flex-shrink: 0;
      width: 15px;
      height: 15px;
      margin-right: 10px;
      background: no-repeat center / contain url("../../assets/images/screens/main/li-prefix.svg");
    }
  }
}

.playImg {
  width: 50%;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.btn {
  margin-top: 40px;
}
