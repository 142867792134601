@import "../../assets/styles/variables";
@import "../../assets/styles/breakpoints";

.formWrapper {
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
  border-radius: 8px;
  color: $color-description;
  background-color: $color-white;
  overflow: auto;
  flex-basis: 100% !important;
}

.row {
  //display: flex;
  //flex-direction: column;
  //align-items: flex-start;
  //justify-content: center;
}

.item {
  display: flex;
  padding: 20px 30px;
  width: 100%;
  background-image: url("../../assets/images/common/star.svg");
  background-position: 0 35%;
  background-size: 20px 20px;
  background-repeat: no-repeat;
}

.item_icon {
  //width : 20px;
}

.item_data {
  display: flex;
  flex-direction: column;
  padding: 5px 28px;
}

.item_data_text {
  font-weight: 500;
  font-size: 16px;
  color: rgb(2, 30, 41);
  margin-bottom: 14px;
}

.item_data_subtext {
  font-size: 14px;
  color: rgb(84, 94, 96);
  line-height: 18px;
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  grid-template-columns: 320px 1fr;
  gap: 10px;
}

.button {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 18px;
  cursor: pointer;
  user-select: none;
  min-width: 100px;
  //background: #030B23;
  //color: #fff;
  background: rgb(244, 245, 245);
  color: #030B23;

  &:hover {
    background: #030B23;
    color: #fff;
  }
}

.button.active {
  background: #030B23;
  color: #fff;
}

.button_submit {
  background: #030B23;
  text-transform: uppercase;
  color: #fff;
  min-width: 320px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  height: 40px;
  border-radius: 6px;

  &:hover {
    background: #3588ea;
    color: #FFF;
  }
}


.closeBtn {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 25px;
  right: 25px;

  @include media-breakpoint-down(md) {
    top: 10px;
    right: 10px;
  }

  &:after, &:before {
    content: "";
    position: absolute;
    display: block;
    top: 10px;
    left: 50%;
    width: 2px;
    height: 20px;
    background-color: #ADA9A9;
    transition: .2s;
  }

  &:after {
    transform: translateX(-50%) rotate(45deg);
  }

  &:before {
    transform: translateX(-50%) rotate(-45deg);
  }

  &:hover {
    &:after, &:before {
      background-color: #696969;
    }
  }
}

.form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.title {
  margin-bottom: 20px;
  font-size: 3rem;

  @include media-breakpoint-down(md) {
    font-size: 1.75rem;
    margin-bottom: 10px;
  }
}

.subTitle {
  position: relative;
  margin-bottom: 20px;
  font-size: 1.25rem;
}

.input, .textarea {
  flex-basis: 100%;
  min-width: 120px;
  margin-bottom: 20px;
  padding: 10px 15px;
  border-radius: 8px;
  border: 1px solid #CBCBCB;
  font-size: 1.25rem;
  color: $color-description;
  background-color: #F2F2F2;
  transition: .2s;
  resize: none;

  &:placeholder-shown {
    text-overflow: ellipsis;
  }

  &::placeholder {
    color: $color-placeholder;
  }

  @include media-breakpoint-down(md) {
    padding: 10px 15px;
    margin-bottom: 10px;
  }

  @include media-breakpoint-down(sm) {
    padding: 10px 7px;
    font-size: 14px;
  }

  @include media-breakpoint-down(xs) {
    font-size: 12px;
  }
}

.textarea {
  margin-bottom: 50px;

  @include media-breakpoint-down(md) {
    margin-bottom: 20px;
    max-height: 100px;
  }
}

.btn {
  width: 60%;
  background-color: $color-main;
  padding: 20px 0;
  border-radius: 8px;
  color: $color-white;
  font-size: 1.25rem;
  text-transform: uppercase;
  transition: .2s;

  &:hover {
    box-shadow: 0 0 16px 1px rgba(1, 105, 227, 0.8);
  }

  @include media-breakpoint-down(md) {
    padding: 10px 0;
  }
}

.input[name=fullName], .input[name=email], .input[name=company], .input[name=phone] {
  @include media-breakpoint-down(lg) {
    flex-basis: calc(50% - 5px);
  }

  @include media-breakpoint-down-height(920px) {
    flex-basis: calc(50% - 5px);
  }
}

.invalid {
  background-color: rgba(255, 160, 160, 0.2);
  border-color: #FFA0A0;
}

.errorMessage {
  position: relative;
  bottom: 5px;
  font-size: 1rem;
  color: #FF2E00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 20px;
}
