@import "../../assets/styles/breakpoints";

.container {
  max-width: 1200px;
  width: 90%;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;

  @include media-breakpoint-down(md) {
    width: 100%;
  }
}
