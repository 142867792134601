@import "../../assets/styles/variables";
@import "../../assets/styles/breakpoints";

.button {
  padding: 20px 40px;
  border-radius: 8px;
  font-size: 1.25rem;
  font-weight: 500;
  text-transform: uppercase;
  white-space: nowrap;
  background-color: $color-main;
  transition: .2s;
  color: #FFF;

  &:hover {
    box-shadow: 0 0 16px 1px rgba(153, 193, 48, 0.8);
  }

  @include media-breakpoint-down(lg) {
    margin-top: 50px;
    padding: 15px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 14px;
  }

  @include media-breakpoint-down(xs) {
    padding: 12px 6px;
  }
}

.formContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(3, 11, 35, 0.70);
  z-index: 10;

  > .contactForm, > .resultMessage {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 90%;
    max-height: 90vh;
    max-width: 600px;
    transform: translate(-50%, -50%);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: #F2F2F2;
      width: 4px;
      border-radius: 0 4px 4px 0;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px;
      border-radius: 4px;
      background-color: #CBCBCB;
      border: 1px solid #CBCBCB;
      box-sizing: border-box;
    }
  }

  > .resultMessage {
    padding: 80px 40px;
  }
}
