@import "../../assets/styles/variables";
@import "../../assets/styles/breakpoints";

.product {
  padding: 100px 0;
  color: $color-white;
  background: $color-bg-main no-repeat calc(100% - 20px) calc(100% + 50px) url("../../assets/images/screens/product/background-image.svg");

  @include media-breakpoint-down(md) {
    background-position: -200px 100%;
  }
}

.largeText {
  text-align: center;
  font-size: 2.25rem;
  font-weight: 500;

  @include media-breakpoint-down(xl) {
    font-size: 1.875rem;
  }

  @include media-breakpoint-down(lg) {
    font-size: 1.5rem;
  }

  @include media-breakpoint-down(md) {
    font-size: 1.375rem;
  }
}

.subTitle {
  margin: 40px 0;
  font-size: 1.25rem;
  text-align: center;
  color: $color-secondary;
}

.contactUs {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  border-radius: 24px;
  background-color: rgba(16, 23, 45, 0.6);
  backdrop-filter: blur(160px);
  opacity: 0.9;

  @include media-breakpoint-down(md) {
    padding: 25px;
  }
}

.carousel {
  position: relative;
  margin: 70px auto;
  padding: 0 50px;

  [class=slick-list] {
    padding-top: 50px !important;

    @include media-breakpoint-down(sm) {
      padding-top: 0 !important;
    }
  }

  [class*=slick-center] {
    position: relative;
    bottom: 20px;
    color: white !important;

    @include media-breakpoint-down(md) {
      bottom: 10px;
    }

    .slide {
      color: white;
    }

    .slideIcon {
      width: 140px;
      height: 140px;
      border: 3px solid $color-main;

      @include media-breakpoint-down(xl) {
        width: 90px;
        height: 90px;
      }

      @include media-breakpoint-down(md) {
        width: 50px;
        height: 50px;
      }
    }
  }
}

.slide {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  color: #D9D3D3;
  text-align: center;

  @include media-breakpoint-down(sm) {
    color: white;
  }
}

.slideIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  margin-bottom: 15px;
  border: 2px solid gray;
  border-radius: 50%;
  box-sizing: content-box;
  padding: 15px;
  background: no-repeat center / contain url("../../assets/images/screens/product/slide-bg.png");

  > img {
    width: 80%;
  }

  @include media-breakpoint-down(xl) {
    width: 60px;
    height: 60px;
  }

  @include media-breakpoint-down(md) {
    width: 36px;
    height: 36px;
  }

  @include media-breakpoint-down(sm) {
    bottom: 20px;
    width: 76px;
    height: 76px;
    border: 3px solid $color-main;
  }
}

.carouselBg {
  position: absolute;
  left: 48px;
  right: 48px;
  top: 0;
  bottom: 0;
  background: linear-gradient(90deg, rgba(3, 11, 35, 0.8) 25%, rgba(9, 9, 121, 0) 50%, rgba(3, 11, 35, 0.8) 75%);
  z-index: 1;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.sportIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 90%;
  margin: 50px auto 80px auto;

  @include media-breakpoint-down(md) {
    width: 100%;
    margin: 20px auto 40px auto;
  }

  > img {
    height: 120px;
    display: block;
    margin: 20px;

    @include media-breakpoint-down(md) {
      height: 90px;
      flex-basis: calc(50% - 60px);
    }
  }
}

.btn {
  margin-top: 30px;
}
