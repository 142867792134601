@import "../../assets/styles/variables";
@import "../../assets/styles/breakpoints";

.about {
  background-color: $color-bg-secondary;
  padding: 150px 0 260px 0;
  color: $color-description;
  background-image: url("../../assets/images/screens/about/background.png");
  background-repeat: no-repeat;
  background-size: 700px;
  background-position: -100px 100%;

  @include media-breakpoint-down(lg) {
    padding-top: 100px;
    padding-bottom: 300px;
    background-position: -200px calc(100% + 100px);
  }

  @include media-breakpoint-down(md) {
    padding-bottom: 350px;
  }

  @include media-breakpoint-down(sm) {
    background-size: 480px;
    background-position: center 100%;
  }
}

.container {
  display: flex;
  justify-content: flex-end;
}

.content {
  width: 60%;

  @include media-breakpoint-down(lg) {
    width: 80%;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.subTitle {
  font-size: 1.75rem;
  font-weight: 500;
  margin-bottom: 30px;
}

.description {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 30px;
  line-height: 30px;
}

.inner {
  display: flex;

  .boxImg {
    width: 200px;
    height: 92px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    box-shadow: 0px 2px 32px rgba(1, 105, 227, 0.1);
    border-radius: 8px;

    &:not(:last-child) {
      margin-right: 15px;
    }

    @include media-breakpoint-down(sm) {
      width: 138px;
      height: 63px;
      box-shadow: 0px 1.4px 22.2px rgba(1, 105, 227, 0.1);
      border-radius: 5.6px;

      &:not(:last-child) {
        margin-right: 10px;
      }

      &:nth-child(1) img {
        width: 84px;
      }

      &:nth-child(2) img {
        width: 91px;
      }
    }
  }
}

.speaker {
  display: flex;
  flex-direction: column;

  &__desc {
    display: flex;
  }

  &__image {
    margin-right: 10px;

    img {
      width: 150px;
      border: 1px solid #99C130;
    }
  }
}
