@import "../../assets/styles/variables";
@import "../../assets/styles/breakpoints";

.formWrapper {
  position: relative;
  padding: 50px 40px;
  border-radius: 8px;
  color: $color-description;
  background-color: $color-white;
  overflow: auto;

  @include media-breakpoint-down-height(920px) {
    padding: 25px 30px;
  }

  @include media-breakpoint-down(md) {
    padding: 40px 20px;
  }

  @include media-breakpoint-down(sm) {
    padding: 15px;
  }
}

.closeBtn {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 25px;
  right: 25px;

  @include media-breakpoint-down(md) {
    top: 10px;
    right: 10px;
  }

  &:after, &:before {
    content: "";
    position: absolute;
    display: block;
    top: 10px;
    left: 50%;
    width: 2px;
    height: 20px;
    background-color: #ADA9A9;
    transition: .2s;
  }

  &:after {
    transform: translateX(-50%) rotate(45deg);
  }

  &:before {
    transform: translateX(-50%) rotate(-45deg);
  }

  &:hover {
    &:after, &:before {
      background-color: #696969;
    }
  }
}

.form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.title {
  margin-bottom: 20px;
  font-size: 3rem;

  @include media-breakpoint-down(md) {
    font-size: 1.75rem;
    margin-bottom: 10px;
  }
}

.subTitle {
  position: relative;
  margin-bottom: 20px;
  font-size: 1.25rem;
}

.input, .textarea {
  flex-basis: 100%;
  min-width: 120px;
  margin-bottom: 20px;
  padding: 10px 15px;
  border-radius: 8px;
  border: 1px solid #CBCBCB;
  font-size: 1.25rem;
  color: $color-description;
  background-color: #F2F2F2;
  transition: .2s;
  resize: none;

  &:placeholder-shown {
    text-overflow: ellipsis;
  }

  &::placeholder {
    color: $color-placeholder;
  }

  @include media-breakpoint-down(md) {
    padding: 10px 15px;
    margin-bottom: 10px;
  }

  @include media-breakpoint-down(sm) {
    padding: 10px 7px;
    font-size: 14px;
  }

  @include media-breakpoint-down(xs) {
    font-size: 12px;
  }
}

.textarea {
  margin-bottom: 50px;

  @include media-breakpoint-down(md) {
    margin-bottom: 20px;
    max-height: 100px;
  }
}

.btn {
  width: 60%;
  background-color: $color-main;
  padding: 20px 0;
  border-radius: 8px;
  color: $color-white;
  font-size: 1.25rem;
  text-transform: uppercase;
  transition: .2s;

  &:hover {
    box-shadow: 0 0 16px 1px rgba(1, 105, 227, 0.8);
  }

  @include media-breakpoint-down(md) {
    padding: 10px 0;
  }
}

.input[name=fullName], .input[name=email], .input[name=company], .input[name=phone] {
  @include media-breakpoint-down(lg) {
    flex-basis: calc(50% - 5px);
  }

  @include media-breakpoint-down-height(920px) {
    flex-basis: calc(50% - 5px);
  }
}

.invalid {
  background-color: rgba(255, 160, 160, 0.2);
  border-color: #FFA0A0;
}

.errorMessage {
  position: relative;
  bottom: 5px;
  font-size: 1rem;
  color: #FF2E00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 20px;
}
