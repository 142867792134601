$breakpoints: (
        xl: 1200px,
        lg: 960px,
        md: 640px,
        sm: 480px,
        xs: 320px,
);

@function breakpoint-max($name) {
  $max: map-get($breakpoints, $name);
  @return if($max and $max > 0, $max - .02, null);
}

@mixin media-breakpoint-down($name) {
  $max: breakpoint-max($name);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-down-height($height: 768px) {
  @media (max-height: $height) {
    @content;
  }
}
