@import "./breakpoints";
@import "./variables";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

html, body {
  height: 100%;
  user-select: none;
}

html {
  font-size: 16px;
  scroll-behavior: smooth;

  @include media-breakpoint-down(lg) {
    font-size: 14px;
  }
}

body {
  position: relative;
  overflow-x: hidden;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
}

a {
  text-decoration: none;
  color: $color-main;
}

input, textarea {
  outline: none;

  @include media-breakpoint-down(md) {
    font-size: 16px !important;
  }
}

textarea,
input,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

@include media-breakpoint-down(sm) {
  button:hover {
    box-shadow: none !important;
  }
}

.yellow {
  color: #ff2f2f;
  white-space: nowrap;

  @include media-breakpoint-down(lg) {
    white-space: normal;
  }
}

.blue {
  color: $color-main;
  white-space: nowrap;

  @include media-breakpoint-down(md) {
    white-space: normal;
  }
}

.bold {
  font-weight: 500;
}

.stop-scroll {
  overflow: hidden;
  touch-action: none;
  -ms-touch-action: none
}
