@import "../../assets/styles/variables";
@import "../../assets/styles/breakpoints";

.header {
  height: 105px;
  background-color: $color-bg-main;
  color: white;
  z-index: 2;

  @include media-breakpoint-down(lg) {
    height: 148px;
  }

  @include media-breakpoint-down(md) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 105px;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  height: 100%;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    justify-content: center;
  }

  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @include media-breakpoint-down(sm) {
    padding: 0 32px;
  }

  @include media-breakpoint-down(xs) {
    padding: 0 16px;
  }
}

.navigation {
  display: flex;
  justify-content: space-evenly;

  @include media-breakpoint-down(lg) {
    margin-top: 30px;
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.switchLanguage {
  @include media-breakpoint-down(lg) {
    position: absolute;
    top: 42px;
    right: 65px;
  }

  @include media-breakpoint-down(md) {
    position: static;
  }
}

.navItem {
  position: relative;
  width: max-content;
  color: white;
  white-space: nowrap;
  text-transform: uppercase;

  &:not(:last-child) {
    margin-right: 55px;
  }
}

.logo {
  margin-right: 15px;
  flex-shrink: 1;

  > svg {
    width: 100%;
  }

  @include media-breakpoint-down(lg) {
    display: block;
    margin: 0 auto;
  }

  @include media-breakpoint-down(md) {
    margin: 0 25px 0 0;
  }

  @include media-breakpoint-down(xs) {
    width: 142px;
  }
}

.other {
  display: flex;
  align-items: center;
}

.menuItem {
  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: -8px;
    width: 0;
    height: 2px;
    background-color: $color-main;
    overflow: hidden;
    transition: .3s;
  }
}

@media (hover: hover) and (pointer: fine) {
  .menuItem:hover::after {
    width: 100%;
  }
}
