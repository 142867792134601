@import "../../assets/styles/variables";

.container {
  position: relative;
}

.list {
  position: absolute;
  top: 25px;
}

.listItem, .activeItem {
  color: white;
  font-size: 1.2rem;
  text-transform: uppercase;
  transition: .2s;
}

.listItem:hover {
  color: $color-main;
}

.activeItem {
  display: flex;
  align-items: center;

  &:after {
    content: "";
    width: 12px;
    height: 8px;
    margin-left: 8px;
    background: no-repeat center / contain url("../../assets/images/common/arrow.svg");
    transition: .2s;
  }

  &:hover {
    color: $color-placeholder;
  }

  &.open {
    color: $color-placeholder;

    &:after {
      transform: rotate(180deg);
    }
  }
}
