@import "../../assets/styles/variables";
@import "../../assets/styles/breakpoints";

.formWrapper {
  position: relative;
  padding: 80px 40px;
  border-radius: 8px;
  color: $color-description;
  background-color: $color-white;
  overflow: auto;
  margin-bottom: 260px;

  @include media-breakpoint-down-height(920px) {
    padding: 25px 30px;
    margin-bottom: unset;
  }

  @include media-breakpoint-down(md) {
    padding: 40px 20px;
  }

  @include media-breakpoint-down(sm) {
    padding: 15px;
  }
}

.closeBtn {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 25px;
  right: 25px;

  @include media-breakpoint-down(md) {
    top: 10px;
    right: 10px;
  }

  &:after, &:before {
    content: "";
    position: absolute;
    display: block;
    top: 10px;
    left: 50%;
    width: 2px;
    height: 20px;
    background-color: #ADA9A9;
    transition: .2s;
  }

  &:after {
    transform: translateX(-50%) rotate(45deg);
  }

  &:before {
    transform: translateX(-50%) rotate(-45deg);
  }

  &:hover {
    &:after, &:before {
      background-color: #696969;
    }
  }
}

.title {
  margin-bottom: 20px;
  font-size: 3rem;

  @include media-breakpoint-down(md) {
    font-size: 1.75rem;
    margin-bottom: 10px;
  }
}

.subTitle {
  font-size: 20px;
  line-height: 24px;
}
