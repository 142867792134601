@import "../../assets/styles/variables";
@import "../../assets/styles/breakpoints";

.about {
  background-color: $color-bg-secondary;
  padding: 100px 0;
  color: $color-description;

  @include media-breakpoint-down(md) {
  }
}

.cardsWrapper {
  width: 100%;
  display: grid;
  //grid-template: repeat(6, calc(100px - 7.5px)) / 3fr 2.3fr 2.6fr 2.1fr;
  grid-gap: 15px;

  //@include media-breakpoint-down(lg) {
  //  grid-template: repeat(6, calc(100px - 7.5px)) 100px 100px / 1fr 1.2fr 1.3fr;
  //}

  @include media-breakpoint-down(md) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  @include media-breakpoint-down(sm) {
    grid-gap: 0;
  }
}

.card {
  position: relative;
  display: flex;
  padding: 30px;
  border-radius: 8px;
  background: no-repeat white 35px 35px;
  box-shadow: 0 2px 32px 0 #0169E31A;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    top: -100px;
    right: -100px;
    opacity: .15;
    width: 220px;
    height: 220px;
    background: no-repeat center / contain;
  }

  @include media-breakpoint-down(md) {
    height: 200px;
    flex-basis: calc(50% - 15px);
    background-position: 35px 35px !important;
  }

  @include media-breakpoint-down(sm) {
    flex-basis: 100%;
    margin-bottom: 15px;
  }
}

.ffm, .fpl {
  color: $color-white;
  background-color: rgba(1, 105, 227, 0.8);

  &:after {
    width: 330px;
    height: 330px;

    @include media-breakpoint-down(md) {
      width: 220px;
      height: 220px;
    }
  }
}

.cardTitle {
  align-self: flex-end;
}

.hqy {
  grid-row: 1/3;

  @include media-breakpoint-down(md) {
    order: 1;
  }
}

.ost {
  grid-row: 3/5;

  @include media-breakpoint-down(md) {
    order: 3;
  }

  @include media-breakpoint-down(sm) {
    order: 2;
  }

  &, &:after {
    background-image: url("../../assets/images/screens/benefits/online-support.svg");
  }
}

.mdy {
  grid-row: 5/7;

  @include media-breakpoint-down(md) {
    order: 5;
  }

  @include media-breakpoint-down(sm) {
    order: 6;
  }

  &, &:after {
    background-image: url("../../assets/images/screens/benefits/minimum-delay.svg");
  }
}

.ffm {
  grid-row: 1/5;
  background-position: 50% 70%;

  @include media-breakpoint-down(md) {
    order: 2;
  }

  @include media-breakpoint-down(sm) {
    order: 3;
  }

  &, &:after {
    background-image: url("../../assets/images/screens/benefits/free-first-month.svg");
  }

  &:after {
    top: 0;
    left: -130px;

    @include media-breakpoint-down(md) {
      top: -100px;
      left: initial;
    }
  }
}

.fpl {
  grid-row: 5/7;
  grid-column: 2/4;
  background-position: 40px 50%;

  @include media-breakpoint-down(md) {
    order: 7;
  }

  @include media-breakpoint-down(sm) {
    order: 4;
  }

  &, &:after {
    background-image: url("../../assets/images/screens/benefits/flexible-price-list.svg");
  }
}

.fin {
  grid-row: 1/3;

  @include media-breakpoint-down(md) {
    order: 4;
  }

  @include media-breakpoint-down(sm) {
    order: 5;
  }

  &, &:after {
    background-image: url("../../assets/images/screens/benefits/fast-integration.svg");
  }
}

.fan {
  grid-row: 3/5;

  @include media-breakpoint-down(md) {
    order: 6;
  }

  @include media-breakpoint-down(sm) {
    order: 7;
  }

  &, &:after {
    background-image: url("../../assets/images/screens/benefits/fast-adaptation.svg");
  }
}

.mte {
  grid-row: 1/4;

  @include media-breakpoint-down(md) {
    order: 8;
  }

  &, &:after {
    background-image: url("../../assets/images/screens/benefits/maximum-top-events.svg");
  }

  @include media-breakpoint-down(lg) {
    grid-column: 1/3;
  }
}

.ats {
  grid-row: 4/7;

  @include media-breakpoint-down(md) {
    order: 9;
  }

  &, &:after {
    background-image: url("../../assets/images/screens/benefits/ability-to-simultaneously.svg");
  }
}

.mte, .ats {
  background-position-y: 40%;

  @include media-breakpoint-down(lg) {
    background-position-y: 30%;
    grid-row: 7/9;
  }
}
